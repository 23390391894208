// =============================
// Color
// =============================
$palette-primary-thin: #fcd2da;
$palette-primary-xLight: #efb4c1;
$palette-primary-light: #ffe1e7;
$palette-primary-main: #ff84aa;
$palette-primary-dark: #ff6696;

$palette-secondary-light: #3fa5ec;
$palette-secondary-main: #147bc2;
$palette-secondary-dark: #0d5485;

$palette-dark-light: #6b84ae;
$palette-dark-main: #3c4a62;
$palette-dark-dark: #353541;

$palette-error-light: #fc8f8f;
$palette-error-main: #fc4343;
$palette-error-dark: #7c2121;

$palette-light-light: #f9fbff;
$palette-light-main: #d5dff2;
$palette-light-dark: #b3c4e6;

$palette-grey-light: #dcddde;
$palette-grey-main: #85888c;
$palette-grey-dark: #4a4d4f;

$palette-white: #fff;
$palette-black: #000;

// =============================
// Shadow
// =============================
$shadow-primary-1: 0 1px 1px 0 rgba(0, 203, 77, 0.14),
0 2px 1px -1px rgba(0, 203, 77, 0.12),
0 1px 3px 0 rgba(0, 203, 77, 0.2);
$shadow-primary-3: 0 3px 4px 0 rgba(0, 203, 77, 0.14),
0 3px 3px -2px rgba(0, 203, 77, 0.12),
0 1px 8px 0 rgba(0, 203, 77, 0.2);
$shadow-primary-4: 0 4px 5px 0 rgba(0, 203, 77, 0.14),
0 1px 10px 0 rgba(0, 203, 77, 0.12),
0 2px 4px -1px rgba(0, 203, 77, 0.2);
$shadow-primary-6: 0 6px 10px 0 rgba(0, 203, 77, 0.14),
0 1px 18px 0 rgba(0, 203, 77, 0.12),
0 3px 5px -1px rgba(0, 203, 77, 0.2);
$shadow-primary-8: 0 8px 10px 1px rgba(0, 203, 77, 0.14),
0 3px 14px 2px rgba(0, 203, 77, 0.12),
0 5px 5px -3px rgba(0, 203, 77, 0.2);

$shadow-secondary-1: 0 1px 1px 0 rgba(18, 113, 179, 0.14),
0 2px 1px -1px rgba(18, 113, 179, 0.12),
0 1px 3px 0 rgba(18, 113, 179, 0.2);
$shadow-secondary-3: 0 3px 4px 0 rgba(18, 113, 179, 0.14),
0 3px 3px -2px rgba(18, 113, 179, 0.12),
0 1px 8px 0 rgba(18, 113, 179, 0.2);
$shadow-secondary-4: 0 4px 5px 0 rgba(18, 113, 179, 0.14),
0 1px 10px 0 rgba(18, 113, 179, 0.12),
0 2px 4px -1px rgba(18, 113, 179, 0.2);
$shadow-secondary-6: 0 6px 10px 0 rgba(18, 113, 179, 0.14),
0 1px 18px 0 rgba(18, 113, 179, 0.12),
0 3px 5px -1px rgba(18, 113, 179, 0.2);
$shadow-secondary-8: 0 8px 10px 1px rgba(18, 113, 179, 0.14),
0 3px 14px 2px rgba(18, 113, 179, 0.12),
0 5px 5px -3px rgba(18, 113, 179, 0.2);

$shadow-dark-1: 0 1px 1px 0 rgba(124, 135, 156, 0.14),
0 2px 1px -1px rgba(124, 135, 156, 0.12),
0 1px 3px 0 rgba(124, 135, 156, 0.2);
$shadow-dark-3: 0 3px 4px 0 rgba(124, 135, 156, 0.14),
0 3px 3px -2px rgba(124, 135, 156, 0.12),
0 1px 8px 0 rgba(124, 135, 156, 0.2);
$shadow-dark-4: 0 4px 5px 0 rgba(124, 135, 156, 0.14),
0 1px 10px 0 rgba(124, 135, 156, 0.12),
0 2px 4px -1px rgba(124, 135, 156, 0.2);
$shadow-dark-6: 0 6px 10px 0 rgba(124, 135, 156, 0.14),
0 1px 18px 0 rgba(124, 135, 156, 0.12),
0 3px 5px -1px rgba(124, 135, 156, 0.2);
$shadow-dark-8: 0 8px 10px 1px rgba(124, 135, 156, 0.14),
0 3px 14px 2px rgba(124, 135, 156, 0.12),
0 5px 5px -3px rgba(124, 135, 156, 0.2);

// =============================
// Overwrite Bootstrap Variables
// =============================
$font-family-sans-serif: 'Poppins',
-apple-system,
BlinkMacSystemFont,
'LiHei Pro',
'STHeiti',
'Yu Gothic',
'SimHei',
'Segoe UI',
'Helvetica Neue',
Arial,
sans-serif,
'Segoe UI Emoji',
'Segoe UI Symbol';
$font-family-body: 'Roboto',
-apple-system,
BlinkMacSystemFont,
'Lantinghei TC',
'Lantinghei SC',
'LiHei Pro',
'STHeiti',
'Microsoft JhengHei',
'Segoe UI',
'Helvetica Neue',
Arial,
sans-serif,
'Segoe UI Emoji',
'Segoe UI Symbol';

// Grid System
$grid-gutter-width: 32px;
$grid-breakpoints: (xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
);

// Utilities
$border-radius: 8px;
$box-shadow-sm: $shadow-primary-1;
$box-shadow: $shadow-primary-3;
$box-shadow-lg: $shadow-primary-6;

$body-color: $palette-dark-dark;
$text-muted: $palette-grey-dark;

// =============================
// Overwrite Vendor Variables
// =============================

$slick-font-family: $font-family-sans-serif;