// override bootstrap variables and add custom ones
@import './custom_variable';

/*=====================
    Global Setting
=======================*/
* {
	font-family: $font-family-sans-serif;
	margin: 0;
}

.font-family-brand {
	font-family: $font-family-sans-serif !important;
}

.font-family-body {
	font-family: $font-family-body !important;
}

body {
	width: 100vw;
	color: $palette-dark-dark !important;
	overflow-x: hidden;
	overflow-y: auto;
	padding-right: 0 !important;
}

a,
p,
button,
input,
div,
span,
td,
textarea {
	border: none;
	text-decoration: none;

	&:focus {
		outline: none !important;
	}
}

input:-webkit-autofill {
	-webkit-text-fill-color: $palette-dark-dark !important;
	animation-name: autofill;
	animation-fill-mode: both;
	-webkit-box-shadow: 0 0 0 30px white inset;
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid $palette-grey-light;
	margin: 0;
	padding: 0;
	width: 100%;
}

// .mdi-icon {
//     flex-shrink: 0;
//     user-select: none;
// }

/*=====================
    Custom Class
=======================*/

@import 'typography';
@import 'animate';
@import 'color';
@import 'boxShadow';
@import 'utility';
@import 'scrollbar';

/*=====================
    Library
=======================*/

// import only the Bootstrap modules you need, e.g.
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/grid';

// import vendors
@import './custom/custom_slider';
@import './custom/custom_recharts';
@import './custom/custom_toastify';
@import '~slick-carousel/slick/slick';
@import '~slick-carousel/slick/slick-theme';