@import './custom_variable';
/*=====================
   Utility
=======================*/

.text-reset {
    font: inherit !important;
    color: inherit !important;
    letter-spacing: inherit !important;
}

.rounded-pill {
    border-radius: 9999px !important;
}

.vh-100 {
    height: 100vh;
}

.overflow-hidden {
    overflow: hidden !important;
}

.hidden {
    position: fixed !important;
    opacity: 0 !important;
    cursor: default !important;
    color: transparent !important;
    background-color: transparent !important;
    border-color: transparent !important;
    pointer-events: none !important;
}

.hide {
    opacity: 0 !important;
    pointer-events: none;
}

.show {
    opacity: 1 !important;
    pointer-events: auto;
}

.no-pointer {
    pointer-events: none;
}

.center-items {
    display: flex !important;
    align-items: center;
}

.font-weight-black {
    font-weight: 900 !important;
}

.d-grid {
    display: grid !important;
}

/*      Z Index     */
.z-body {
    z-index: 900;
}

.z-dropdown {
    z-index: 1000;
}

.z-sticky {
    z-index: 1100;
}

.z-fixed {
    z-index: 1200;
}

.z-modal-backdrop {
    z-index: 1300;
}

.z-modal {
    z-index: 1400;
}

.z-popover {
    z-index: 1500;
}

.z-tooltip {
    z-index: 1600;
}

.z-top {
    z-index: 2000;
}

/*      Align     */

.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.absolute-horizontal {
    position: absolute !important;
    left: 50%;
    transform: translateX(-50%);
}

.absolute-vertical {
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);
}

/*      Spacing     */

.mb-32 {
    margin-bottom: 32px !important;
}

.mt-32 {
    margin-top: 32px !important;
}

.mr-32 {
    margin-right: 32px !important;
}

.ml-32 {
    margin-left: 32px !important;
}

.px-32 {
    padding-right: 32px !important;
    padding-left: 32px !important;
}

.pr-32 {
    padding-right: 32px !important;
}

.pl-32 {
    padding-left: 32px !important;
}

.py-32 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
}

.pt-32 {
    padding-top: 32px !important;
}

.pb-32 {
    padding-bottom: 32px !important;
}

/*      Cursor     */

.c-auto {
    cursor: auto;
}

.c-help {
    cursor: help;
}

.c-wait {
    cursor: wait;
}

.c-cross {
    cursor: crosshair;
}

.c-disabled {
    cursor: not-allowed;
}

.c-zoomIn {
    cursor: zoom-in;
}

.c-grab {
    cursor: grab;
}

.c-pointer {
    cursor: pointer;
}
